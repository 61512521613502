<template>
	<span class="select-arrow" aria-hidden="true">
		<img src="../../img/arrows.svg" alt=""/>
	</span>
</template>

<script>
export default {}
</script>

